import Vue from 'vue'
import store from '../store';
import Router from 'vue-router'

import address from "../views/Address/router";
import application from "../views/Application/router";
import cookies from "../views/Cookies/router";
import dashboard from "../views/Dashboard/router";
import emailAlert from "../views/EmailAlert/router";
import emailConfirm from "../views/EmailConfirm/router";
import httpNotFound from "../views/HttpNotFound/router";
import login from "../views/Login/router";
import order from "../views/Order/router";
import orders from "../views/Orders/router";
import policies from "../views/Policies/router";
import profile from "../views/Profile/router";
import register from "../views/Register/router";
import resetPassword from "../views/ResetPassword/router";
import settings from "../views/Settings/router";
import telephone from "../views/Telephone/router";
import terms from "../views/Terms/router";
import thirdPartyLogin from "../views/ThirdPartyLogin/router";

Vue.use(Router);

const router = new Router({
  mode: 'history',
  base: process.env.BASE_URL,
  routes: [
    {
      path: '/',
      redirect: { name: 'dashboard', params: { status: 'PENDING' } }
    },
    ...address,
    ...application,
    ...cookies,
    ...dashboard,
    ...emailAlert,
    ...emailConfirm,
    ...httpNotFound,
    ...login,
    ...order,
    ...orders,
    ...policies,
    ...profile,
    ...register,
    ...resetPassword,
    ...settings,
    ...telephone,
    ...terms,
    ...thirdPartyLogin,
    {
      path: '*',
      name: 'not-found',
      redirect: { name: 'http_not_found' },
    },
  ],
  scrollBehavior (to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition
    } else {
      return { x: 0, y: 0 }
    }
  }
});

router.beforeEach((to, from, next) => {
  let authCheck = store.getters.getLoginAuthCheck;
  let auth = to.matched.some(record => record.meta.requiresAuth);

  if (auth && !authCheck) {
    next({ name: 'login' })
  } else {
    next();
  }
});

export default router;
