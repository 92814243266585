<template>
  <v-card flat outlined class="fill-height" v-bind="$attrs" v-if="order">
    <v-list-item two-line :to="{ name: 'order', params: { order: order.id } }">
      <v-list-item-avatar rounded size="80">
        <v-img :src="order.business.thumbnail ? order.business.thumbnail.url : require('../assets/placeholder.png')"
               aspect-ratio="1"/>
      </v-list-item-avatar>
      <v-list-item-content>
        <v-list-item-title>
          #{{ order.identification }} - <span :class="orderStatusColor(order.status)">{{ orderStatus(order.status) }}</span>
        </v-list-item-title>
        <v-list-item-subtitle>
          {{ order.business.name }}
        </v-list-item-subtitle>
        <v-list-item-subtitle>
          Previsão {{ DateTime.fromSQL(order.delivery_prevision).setLocale('pt-BR').toFormat('dd LLL yyyy t') }}
        </v-list-item-subtitle>
        <v-list-item-subtitle>
          Valor: {{ new Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' }).format(order.total) }}
        </v-list-item-subtitle>
      </v-list-item-content>
    </v-list-item>
    <v-card-actions v-if="order.status === 'PENDING'">
      <v-spacer/>
      <v-btn text @click="cancelOrder">Rejeitar</v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
  import { DateTime } from "luxon";

  export default {
    name: 'a-order-tile',
    props: {
      order: Object,
    },
    data: () => ({
      businessAddressDialog: false,
      orderAddressDialog: false,
      DateTime: DateTime,
    }),
    methods: {
      cancelOrder() {
        this.$axios.delete(`/drivers/${this.driver.id}/orders/${this.driver.id}`)
          .then((response) => {
            this.order = response.data.data;
          });
      },
      orderStatusColor(status) {
        switch (status) {
          case 'PENDING':
            return 'amber--text darken-4';
          case 'PREPARING':
            return 'blue--text darken-4';
          case 'DELIVERING':
            return 'purple--text darken-4';
          case 'DELIVERED':
            return 'green--text darken-4';
          case 'CANCELLED':
            return 'red--text darken-4';
          default:
            return 'grey--text darken-4';
        }
      },
      orderStatus(status) {
        switch (status) {
          case 'PENDING':
            return 'Pendente';
          case 'PREPARING':
            return 'Preparando';
          case 'DELIVERING':
            return 'Saiu Para Entrega';
          case 'DELIVERED':
            return 'Entregue';
          case 'CANCELLED':
            return 'Cancelado';
          default:
            return 'Não definido';
        }
      },
    },
    computed: {
      driver() {
        return this.$store.getters.getLoginAuthDriver;
      },
    },
  };
</script>
