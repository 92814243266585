import resource from "@/resource";

const state = {
  profile: null
};

const getters = {
  getProfile(state) {
    return state.profile;
  },
};

const mutations = {
  SET_PROFILE(state, status) {
    state.profile = status;
  },
};

const actions = {
  fetchProfile(context, { driver }) {
    return resource.get(`/drivers/${driver}`)
      .then((response) => {
        context.commit('SET_PROFILE', response.data);
        return Promise.resolve(response);
      }).catch((error) => {
        return Promise.reject(error);
      });
  },
  updateProfile(context, { driver, payload }) {
    return resource.put(`/drivers/${driver}`, payload)
      .then((response) => {
        return Promise.resolve(response);
      }).catch((error) => {
        return Promise.reject(error);
      });
  },
  deleteAccount(context, { driver, payload }) {
    return resource.delete(`/drivers/${driver}`, { data: payload.payload })
      .then((response) => {
        context.dispatch('loginRemoveToken');
        context.dispatch('loginRemoveUser');
        return Promise.resolve(response);
      }).catch((error) => {
        return Promise.reject(error);
      });
  },
};

export default {
  state,
  getters,
  actions,
  mutations
};
