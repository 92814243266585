<template>
  <v-card flat outlined>
    <v-card-text v-if="payload">
      <v-switch inset v-model="payload.online" color="success" @change="updateProfile"
                :label="`Você se encontra ${payload.online ? 'Online' : 'Offline'} para receber pedidos`"/>
      <span class="d-block caption" v-if="driver && driver.toggle_online_at">
        {{ driver.online ? 'Online' : 'Offline' }} desde {{ DateTime.fromSQL(driver.toggle_online_at).setLocale('pt-BR').toFormat('dd LLL yyyy t') }}
      </span>
      <span class="d-block caption">
        Antes de ficar online certifique-se de estar disponível para receber pedidos de entregas!
      </span>
    </v-card-text>
    <v-skeleton-loader type="article" v-else/>
  </v-card>
</template>

<script>
  import { DateTime } from "luxon";

  export default {
    name: 'a-profile-online-switch',
    data: () => ({
      formErrors: {},
      dialog: false,
      payload: null,
      valid: true,
      DateTime: DateTime,
    }),
    created() {
      this.$store.dispatch('fetchProfile', {
        driver: this.driver.id
      }).then((response) => {
        this.payload = {
          online: response.data.data.online,
        }
      });
    },
    methods: {
      updateProfile() {
        this.$store.dispatch('updateProfile', {
          driver: this.driver.id,
          payload: this.payload
        }).then((response) => {
          this.$store.dispatch('loginSetDriver', response.data.data);
        }).catch((error) => {
          if(error.response && error.response.status === 422){
            this.formErrors = error.response.data.errors;
          }
        });
      },
    },
    computed: {
      driver() {
        return this.$store.getters.getLoginAuthDriver;
      },
    }
  };
</script>
