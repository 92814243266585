import resource from "../../resource";

const state = {
  order: null
};

const getters = {
  getOrder(state) {
    return state.order;
  }
};

const mutations = {
  SET_ORDER_ORDER(state, data) {
    state.order = data;
  },
};

const actions = {
  fetchOrder(context, { driver, order }) {
    return resource.get(`/drivers/${driver}/orders/${order}`)
      .then((response) => {
        context.commit('SET_ORDER_ORDER', response.data.data);
        return Promise.resolve(response);
      }).catch(function (error) {
        return Promise.reject(error);
      });
  }
};

export default {
  state,
  getters,
  actions,
  mutations
};
