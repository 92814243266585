import resource from "../../resource";
const tokenKeyName = 'PUB_DRIVER_TOKEN';
const driverKeyName = 'PUB_DRIVER_DRIVER';

const state = {
  token: null,
  driver: null
};

const getters = {
  getLoginToken(state) {
    if (localStorage.getItem(tokenKeyName)){
      state.token = localStorage.getItem(tokenKeyName);
    }
    return state.token;
  },
  getLoginAuthDriver(state) {
    if (localStorage.getItem(driverKeyName)){
      state.driver = JSON.parse(localStorage.getItem(driverKeyName));
    }
    return state.driver;
  },
  getLoginAuthCheck(state) {
    return !!state.token || !!localStorage.getItem(tokenKeyName);
  },
};

const mutations = {
  SET_LOGIN_TOKEN(state, token) {
    state.token = token;
    localStorage.setItem(tokenKeyName, token);
  },
  REMOVE_LOGIN_TOKEN(state) {
    state.token = null;
    localStorage.removeItem(tokenKeyName);
  },
  SET_LOGIN_DRIVER(state, driver) {
    state.driver = driver;
    localStorage.setItem(driverKeyName, JSON.stringify(driver));
  },
  REMOVE_LOGIN_DRIVER(state) {
    state.driver = null;
    localStorage.removeItem(driverKeyName);
  },
};

const actions = {
  loginCreateSession(context, credentials) {
    return resource.post('/auth/driver/login', credentials)
      .then((response) => {
        context.commit('SET_LOGIN_TOKEN', response.data.access_token);
        return context.dispatch('loginFetchAuthDriver');
      }).catch((error) => {
        context.dispatch('loginRemoveToken');
        context.dispatch('loginRemoveDriver');
        return Promise.reject(error);
      });
  },
  loginRemoveSession(context) {
    return resource.post('/auth/driver/logout')
      .then((response) => {
        context.dispatch('loginRemoveToken');
        context.dispatch('loginRemoveDriver');
        return Promise.resolve(response);
      }).catch((error) => {
        context.dispatch('loginRemoveToken');
        context.dispatch('loginRemoveDriver');
        return Promise.reject(error);
      });
  },
  loginRemoveSessions(context) {
    return resource.post('/auth/driver/revoke')
      .then((response) => {
        context.dispatch('loginRemoveToken');
        context.dispatch('loginRemoveDriver');
        return Promise.resolve(response);
      }).catch((error) => {
        return Promise.reject(error);
      });
  },
  loginFetchAuthDriver(context) {
    return resource.get('/auth/driver/me')
      .then((response) => {
        context.dispatch('loginSetDriver', response.data.data);
        return Promise.resolve(response);
      }).catch((error) => {
        context.dispatch('loginRemoveSession');
        return Promise.reject(error);
      });
  },
  loginSetToken(context, data) {
    context.commit('SET_LOGIN_TOKEN', data);
  },
  loginRemoveToken(context) {
    context.commit('REMOVE_LOGIN_TOKEN');
  },
  loginRemoveDriver(context) {
    context.commit('REMOVE_LOGIN_DRIVER');
  },
  loginSetDriver(context, data) {
    context.commit('SET_LOGIN_DRIVER', data);
  },
};

export default {
  state,
  getters,
  actions,
  mutations
};
