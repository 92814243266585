import resource from "../../resource";

const state = {
  orders: null
};

const getters = {
  getDashboardOrders(state) {
    return state.orders;
  }
};

const mutations = {
  SET_DASHBOARD_ORDERS(state, data) {
    state.orders = data;
  },
};

const actions = {
  fetchDashboardOrders(context, { driver }) {
    return resource.get(`/drivers/${driver}/orders`, { params: { all: 1 } })
      .then((response) => {
        context.commit('SET_DASHBOARD_ORDERS', response.data);
        return Promise.resolve(response);
      }).catch(function (error) {
        return Promise.reject(error);
      });
  },
  updateDashboardOrder(context, { driver, order, payload }) {
    return resource.put(`/drivers/${driver}/orders/${order}`, payload)
      .then((response) => {
        return Promise.resolve(response);
      }).catch(function (error) {
        return Promise.reject(error);
      });
  },
  deleteDashboardOrder(context, { driver, order }) {
    return resource.delete(`/drivers/${driver}/orders/${order}`)
      .then((response) => {
        return Promise.resolve(response);
      }).catch((error) => {
        return Promise.reject(error);
      });
  },
};

export default {
  state,
  getters,
  actions,
  mutations
};
