import Vue from 'vue';
import Vuex from 'vuex';

import address from "../views/Address/store.module";
import application from "../views/Application/store.module";
import dashboard from "../views/Dashboard/store.module";
import httpNotFound from "../views/HttpNotFound/store.module";
import login from "../views/Login/store.module";
import order from "../views/Order/store.module";
import profile from "../views/Profile/store.module";
import register from "../views/Register/store.module";
import resetPassword from "../views/ResetPassword/store.module";
import settings from "../views/Settings/store.module";
import telephone from "../views/Telephone/store.module";
import thirdPartyLogin from "../views/ThirdPartyLogin/store.module";

Vue.use(Vuex);

export default new Vuex.Store({
  modules: {
    address,
    application,
    dashboard,
    httpNotFound,
    login,
    order,
    profile,
    register,
    resetPassword,
    settings,
    telephone,
    thirdPartyLogin,
  },
})
