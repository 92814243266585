import { DateTime } from "luxon";

const state = {
  drawer: null,
  loadingDialog: false,
  currentPosition: null,
  showOrderDialog: false,
  orderDialogPayload: null,
  timezone: DateTime.now().toFormat('z'),
  GMT: DateTime.now().toFormat('ZZ'),
  appName: process.env.VUE_APP_APP,
  appVersion: process.env.VUE_APP_VERSION,
  firebaseConfig: {
    apiKey: "AIzaSyCGSmMZTWq47QchNYF6cKFadM35h8cS4NI",
    authDomain: "pointurbano-42108.firebaseapp.com",
    databaseURL: "https://pointurbano-42108.firebaseio.com",
    projectId: "pointurbano-42108",
    storageBucket: "pointurbano-42108.appspot.com",
    messagingSenderId: "815664903213",
    appId: "1:815664903213:web:54c1779a8fe3aa5a99b4a4",
    measurementId: "G-JHMFYXZK6G"
  },
  firebaseMessaging: null,
  echoServer: null,
};

const getters = {
  getDrawerStatus(state) {
    return state.drawer;
  },
  getLoadingDialog(state) {
    return state.loadingDialog;
  },
  getCurrentPosition(state) {
    return state.currentPosition;
  },
  getShowOrderDialog(state) {
    return state.showOrderDialog;
  },
  getOrderDialogPayload(state) {
    return state.orderDialogPayload;
  },
  getTimezone(state) {
    return state.timezone;
  },
  getGMT(state) {
    return state.GMT;
  },
  getAppName(state) {
    return state.appName;
  },
  getAppVersion(state) {
    return state.appVersion;
  },
  getFirebaseConfig(state) {
    return state.firebaseConfig;
  },
  getFirebaseMessaging(state) {
    return state.firebaseMessaging;
  },
  getEchoServer(state) {
    return state.echoServer;
  },
};

const mutations = {
  SET_DRAWER_STATUS(state, data) {
    state.drawer = data;
  },
  SET_LOADING_DIALOG_STATUS(state, data) {
    state.loadingDialog = data;
  },
  SET_CURRENT_POSITION(state, data) {
    state.currentPosition = data;
  },
  SET_SHOW_ORDER_DIALOG(state, data) {
    state.showOrderDialog = data;
  },
  SET_ORDER_DIALOG_PAYLOAD(state, data) {
    state.orderDialogPayload = data;
  },
  SET_TIMEZONE(state, data) {
    state.timezone = data;
  },
  SET_GMT(state, data) {
    state.GMT = data;
  },
  SET_FIREBASE_MESSAGING(state, data) {
    state.firebaseMessaging = data;
  },
  SET_ECHO_SERVER(state, data) {
    state.echoServer = data;
  },
};

const actions = {
  setDrawerStatus(context, data) {
    context.commit('SET_DRAWER_STATUS', data);
  },
  setLoadingDialogStatus(context, data) {
    context.commit('SET_LOADING_DIALOG_STATUS', data);
  },
  setCurrentPosition(context, data) {
    context.commit('SET_CURRENT_POSITION', data);
  },
  setShowOrderDialogStatus(context, data) {
    context.commit('SET_SHOW_ORDER_DIALOG', data);
  },
  setOrderDialogPayload(context, data) {
    context.commit('SET_ORDER_DIALOG_PAYLOAD', data);
  },
  setTimezone(context, data) {
    context.commit('SET_TIMEZONE', data);
  },
  setGMT(context, data) {
    context.commit('SET_GMT', data);
  },
  setFirebaseMessaging(context, data) {
    context.commit('SET_FIREBASE_MESSAGING', data);
  },
  setEchoServer(context, data) {
    context.commit('SET_ECHO_SERVER', data);
  },
};

export default {
  state,
  getters,
  actions,
  mutations
};
