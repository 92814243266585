import resource from '../../resource';

const state = {
  telephones: null,
};

const getters = {
  getTelephones(state) {
    return state.telephones;
  },
};

const mutations = {
  SET_TELEPHONES(state, data) {
    state.telephones = data;
  },
};

const actions = {
  fetchTelephones(context, { driver }) {
    return resource.get(`/drivers/${driver}/telephones`)
      .then((response) => {
        context.commit('SET_TELEPHONES', response.data);
        return Promise.resolve(response);
      }).catch((error) => {
        return Promise.reject(error);
      });
  },
  fetchTelephone(context, { driver, telephone }) {
    return resource.get(`/drivers/${driver}/telephones/${telephone}`)
      .then((response) => {
        return Promise.resolve(response);
      }).catch((error) => {
        return Promise.reject(error);
      });
  },
  searchTelephones(context, { driver, search }) {
    return resource.get(`/drivers/${driver}/telephones`, { params: { telephone: search } })
      .then((response) => {
        context.commit('SET_TELEPHONES', response.data);
        return Promise.resolve(response);
      }).catch((error) => {
        return Promise.reject(error);
      });
  },
  toTelephonesPage(context, { driver, search, page }) {
    return resource.get(`/drivers/${driver}/telephones`, { params: { telephone: search, page: page } })
      .then((response) => {
        context.commit('SET_TELEPHONES', response.data);
        return Promise.resolve(response);
      }).catch((error) => {
        return Promise.reject(error);
      });
  },
  createTelephone(context, { driver, payload }) {
    return resource.post(`/drivers/${driver}/telephones`, payload)
      .then((response) => {
        return Promise.resolve(response);
      }).catch((error) => {
        return Promise.reject(error);
      });
  },
  updateTelephone(context, { driver, telephone, payload }) {
    return resource.put(`/drivers/${driver}/telephones/${telephone}`, payload)
      .then((response) => {
        return Promise.resolve(response);
      }).catch((error) => {
        return Promise.reject(error);
      });
  },
  deleteTelephone(context, { driver, telephone }) {
    return resource.delete(`/drivers/${driver}/telephones/${telephone}`)
      .then((response) => {
        return Promise.resolve(response);
      }).catch((error) => {
        return Promise.reject(error);
      });
  },
};

export default {
  state,
  getters,
  actions,
  mutations
};
