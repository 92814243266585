<template>
  <v-row dense align-content="start" class="fill-height">
    <!--Details-->
    <template v-if="order">
      <v-col cols="12">
        <v-card flat outlined>
          <v-card-title>Itens do pedido</v-card-title>
          <v-card-text>
            <template v-for="orderItem in order.order_items">
              <span class="d-block" :key="`order-item-${orderItem.id}`">{{ orderItem.quantity }}x {{ orderItem.name }} - {{ new Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' }).format(orderItem.total) }}</span>
              <template v-for="orderOption in orderItem.order_options">
                <span class="d-block pl-5" :key="`order-option-${orderOption.id}`">{{ orderOption.name }}</span>
                <template v-for="orderSelection in orderOption.order_selections">
                  <span class="d-block pl-10" :key="`order-selection-${orderSelection.id}`">{{ orderSelection.quantity }}x {{ orderSelection.name }} - {{ new Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' }).format(orderSelection.amount) }}</span>
                </template>
              </template>
              <span class="d-block py-2" :key="`comment-${orderItem.id}`" v-if="orderItem.comments">
              Obs. <span class="text-uppercase">{{ orderItem.comments }}</span>
            </span>
            </template>
          </v-card-text>

          <template v-if="order.service === 'DELIVERY'">
            <v-card-subtitle>Taxa de entrega</v-card-subtitle>
            <v-card-text>
              {{ new Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' }).format(order.delivery_tax) }}
            </v-card-text>
          </template>

          <template v-if="order.coupon">
            <v-card-subtitle>Cupom de desconto</v-card-subtitle>
            <v-card-text v-if="order.coupon.type === 'DECIMAL'">
              {{ new Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' }).format(order.coupon.amount) }} sobre os itens
            </v-card-text>
            <v-card-text v-if="order.coupon.type === 'PERCENTAGE'">
              {{ order.coupon.percentage }}% sobre os itens
            </v-card-text>
            <v-card-text v-if="order.coupon.type === 'DELIVERY_FREE'">
              Frete grátis
            </v-card-text>
          </template>

          <v-card-subtitle>Valor total do pedido</v-card-subtitle>
          <v-card-text>
            {{ new Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' }).format(order.total) }}
          </v-card-text>

          <v-card-subtitle>Pagamento</v-card-subtitle>
          <v-card-text>
            <v-row dense>
              <v-col cols="12" v-for="payment in order.payments" :key="`payment-${payment.id}`">
                <span class="d-block" v-if="payment.type === 'IN_APP'">Pago pelo APP, não há cobrança na entrega.</span>
                <span class="d-block" v-else>Pagamento com <b>{{ paymentType(payment.type) }}</b> (<b>{{ payment.payment_method.name }}</b>) na entrega.</span>
                <span class="d-block" v-if="payment.type === 'CASH' && payment.cash_change">Requer troco para {{ new Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' }).format(payment.cash_change) }}</span>
              </v-col>
            </v-row>
          </v-card-text>

          <template v-if="order.status === 'DELIVERED'">
            <v-card-subtitle>Avaliação</v-card-subtitle>
            <v-card-text v-if="order.rating">
              <v-list-item two-line class="px-0">
                <v-list-item-content>
                  <v-list-item-title>
                    <v-rating dense small half-increments readonly background-color="gray lighten-1" v-model="order.rating.rating"/>
                  </v-list-item-title>
                  <v-list-item-subtitle>
                    <v-icon small class="pb-1">mdi-account</v-icon> {{ order.rating.comment }}
                  </v-list-item-subtitle>
                  <v-list-item-subtitle>
                    <v-icon small class="pb-1">mdi-domain</v-icon> {{ order.rating.replica }}
                  </v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>
            </v-card-text>
            <v-card-text v-else>
              <v-btn depressed block :to="{ name: 'ratings_create', params: { order: order.id } }">Avaliar</v-btn>
            </v-card-text>
          </template>
        </v-card>
      </v-col>
      <template v-if="order.status === 'PREPARING'">
        <v-col cols="12">
          <v-card flat outlined>
            <v-card-title>Endereço do estabelecimento</v-card-title>
            <v-card-subtitle>{{ order.business.name }}</v-card-subtitle>
            <v-card-text v-if="order.business.address">
              <span class="d-block">Endereço: {{ order.business.address.address }}</span>
              <span class="d-block">Bairro: {{ order.business.address.district }}</span>
              <span class="d-block">Comp: {{ order.business.address.complement }}</span>
              <span class="d-block">Ref: {{ order.business.address.reference }}</span>
              <span class="d-block">Cidade: {{ order.business.address.city.name }} - {{ order.business.address.city.state.uf }}</span>
              <span class="d-block">CEP: {{ order.business.address.cep }}</span>
            </v-card-text>
            <v-card-actions v-if="order.business.location">
              <v-btn text block @click="openMap(order.business.location)">Abrir No Mapa</v-btn>
            </v-card-actions>
          </v-card>
        </v-col>
        <v-col cols="12">
          <v-card flat outlined>
            <v-card-title>Sair para entrega</v-card-title>
            <v-card-text>Assim que retirar a entrega no estabelecimento pressione o botão abaixo sair para entrega, para que o cliente saiba que o pedido está à caminho.</v-card-text>
            <v-card-actions>
              <v-btn depressed block color="primary" @click="deliveryOrder">Sair Para Entrega</v-btn>
            </v-card-actions>
          </v-card>
        </v-col>
      </template>
      <template v-if="order.status === 'DELIVERING'">
        <v-col cols="12">
          <v-card flat outlined>
            <v-card-title>Endereço de Destino</v-card-title>
            <v-card-subtitle>{{ order.user.name }}</v-card-subtitle>
            <v-card-text v-if="order.address">
              <span class="d-block">Endereço: {{ order.address.address }}</span>
              <span class="d-block">Bairro: {{ order.address.district }}</span>
              <span class="d-block">Comp: {{ order.address.complement }}</span>
              <span class="d-block">Ref: {{ order.address.reference }}</span>
              <span class="d-block">Cidade: {{ order.address.city.name }} - {{ order.address.city.state.uf }}</span>
              <span class="d-block">CEP: {{ order.address.cep }}</span>
            </v-card-text>
            <v-card-actions v-if="order.address.location">
              <v-btn text block @click="openMap(order.address.location)">Abrir No Mapa</v-btn>
            </v-card-actions>
          </v-card>
        </v-col>
        <v-col cols="12">
          <v-card flat outlined>
            <v-card-title>Código de confirmação*</v-card-title>
            <v-card-text>Assim que entregar o pedido peça o código de confirmação para o recepitor do pedido, pressione o botão abaixo finalizar para finalizar o pedido.</v-card-text>
            <v-card-text>
              <v-otp-input v-model="confirm_code" length="5" type="text" :rules="[$rules.required]"/>
            </v-card-text>
            <v-card-actions>
              <v-btn depressed block color="primary" @click="orderDelivered">Finalizar</v-btn>
            </v-card-actions>
          </v-card>
        </v-col>
      </template>
    </template>
  </v-row>
</template>

<script>
  export default {
    name: 'a-order',
    data: () => ({
      order: null,
      confirm_code: '',
    }),
    created() {
      this.$axios.get(`/drivers/${this.driver.id}/orders/${this.$route.params.order}`)
        .then((response) => {
          this.order = response.data.data;
        });
    },
    methods: {
      openMap(location) {
        let geocoords = `${location.coordinates[1]},${location.coordinates[0]}`;
        if (/android/i.test(navigator.userAgent)) {
          window.open(`geo:0,0?q=${geocoords}`, '_system');
        } else if (/iPad|iPhone|iPod/.test(navigator.userAgent) && !window.MSStream) {
          window.open(`maps://?q=${geocoords}`, '_system');
        } else if (/Win/.test(navigator.userAgent)) {
          window.open(`https://www.google.com/maps/search/?api=1&query=${geocoords}`, '_system');
        } else if (/Mac/i.test(navigator.userAgent)) {
          window.open(`http://maps.apple.com/?ll=${geocoords}`, '_system');
        }
      },
      deliveryOrder() {
        this.$axios.put(`/drivers/${this.driver.id}/orders/${this.order.id}`, { status: 'DELIVERING' })
          .then((response) => {
            this.order = response.data.data;
          });
      },
      orderDelivered() {
        this.$axios.put(`/drivers/${this.driver.id}/orders/${this.order.id}`, { status: 'DELIVERED', confirm_code: this.confirm_code })
          .then((response) => {
            this.order = response.data.data;
          });
      },
      orderStatus(status) {
        switch (status) {
          case 'PENDING':
            return 'Pendente';
          case 'PREPARING':
            return 'Preparando';
          case 'DELIVERING':
            return 'Saiu Para Entrega';
          case 'DELIVERED':
            return 'Entregue';
          case 'CANCELLED':
            return 'Cancelado';
          default:
            return 'Não definido';
        }
      },
      paymentStatus(status) {
        switch (status) {
          case 'PENDING':
            return 'Processando';
          case 'SUCCEEDED':
            return 'Aprovado';
          case 'FAILED':
            return 'Rejeitado';
          case 'CANCELLED':
            return 'Cancelado';
          case 'CASH_BACK':
            return 'Estornado';
          default:
            return 'Não definido';
        }
      },
      paymentType(type) {
        switch (type) {
          case 'IN_APP_PAYMENT':
            return 'No APP';
          case 'CREDIT_CARD':
            return 'Cartão de Crédito';
          case 'CURRENCY':
            return 'Dinheiro';
          case 'DEBIT_CARD':
            return 'Cartão de Debito';
          case 'MEAL_TICKET':
            return 'Vale Refeição';
          default:
            return 'Não definido';
        }
      },
    },
    computed: {
      driver() {
        return this.$store.getters.getLoginAuthDriver;
      },
    },
  };
</script>
