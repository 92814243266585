<template>
  <v-row dense align-content="start" class="fill-height">
    <v-col cols="12">
      <v-card flat dark color="primary">
        <v-list-item three-line>
          <v-list-item-content>
            <v-list-item-title v-if="creating">Criar Telefone</v-list-item-title>
            <v-list-item-title v-if="updating">Alterar Telefone</v-list-item-title>
            <v-list-item-subtitle>Listagem dos dados</v-list-item-subtitle>
          </v-list-item-content>
          <v-list-item-action>
            <v-btn @click="$router.back()" icon>
              <v-icon>mdi-arrow-left</v-icon>
            </v-btn>
          </v-list-item-action>
        </v-list-item>
      </v-card>
    </v-col>
    <template v-if="payload">
      <v-col cols="12">
        <v-card flat outlined>
          <v-card-text>
            <v-form ref="form" v-model="valid">
              <v-row dense>
                <v-col cols="12" md="6">
                  <a-country-search v-model="payload.country_id" label="País*" type="text"
                                :error-messages="formErrors.country_id"
                                hide-details="auto" :rules="[$rules.required]"/>
                </v-col>
                <v-col cols="12" md="6">
                  <v-text-field v-model="payload.telephone" label="Telefone*" type="number"
                                :error-messages="formErrors.telephone"
                                hide-details="auto" :rules="[$rules.required]"/>
                </v-col>
              </v-row>
            </v-form>
          </v-card-text>
          <v-card-actions>
            <v-spacer/>
            <v-btn text @click="$refs.form.reset()">Limpar</v-btn>
            <v-btn depressed color="primary" @click="storeTelephone()" v-if="creating">Salvar</v-btn>
            <v-btn depressed color="primary" @click="updateTelephone()" v-if="updating">Alterar</v-btn>
          </v-card-actions>
        </v-card>
      </v-col>
    </template>
  </v-row>
</template>

<script>
  import ACountrySearch from "@/components/ACountrySearch";
  export default {
    name: 'a-telephone-form',
    components: {ACountrySearch},
    data: () => ({
      formErrors: {},
      payload: {
        telephone: '',
        country_id: ''
      },
      valid: true,
    }),
    created() {
      if (this.updating) {
        this.$store.dispatch('fetchTelephone', {
          driver: this.driver.id,
          telephone: this.$route.params.telephone
        }).then((response) => {
          this.payload = {
            telephone: response.data.data.telephone,
            country_id: response.data.data.country ? response.data.data.country.id : '',
          }
        });
      }
    },
    methods: {
      storeTelephone() {
        if (!this.$refs.form.validate()) {
          alert('Verifique os campos do formulário!');
          return;
        }
        this.$store.dispatch('createTelephone', {
          driver: this.driver.id,
          payload: this.payload
        }).then(() => {
          this.$refs.form.reset();
          this.$router.back();
        }).catch((error) => {
          if (error.response && error.response.status === 422) {
            this.formErrors = error.response.data.errors;
          }
        });
      },
      updateTelephone() {
        if (!this.$refs.form.validate()) {
          alert('Verifique os campos do formulário!');
          return;
        }
        this.$store.dispatch('updateTelephone', {
          driver: this.driver.id,
          telephone: this.$route.params.telephone,
          payload: this.payload
        }).then(() => {
          this.$refs.form.reset();
          this.$router.back();
        }).catch((error) => {
          if (error.response && error.response.status === 422) {
            this.formErrors = error.response.data.errors;
          }
        });
      },
    },
    computed: {
      creating() {
        return this.$route.name === 'telephones_create';
      },
      updating() {
       return this.$route.name === 'telephones_update';
      },
      driver() {
        return this.$store.getters.getLoginAuthDriver;
      },
    },
  };
</script>
